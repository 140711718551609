import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { FormGroup, FormControl, Validators, FormBuilder } from "@angular/forms";
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { HomeComponent } from '../home/home.component';
@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  isLoading: boolean = false
  selectedFile: any = null;
  imgUrl: any
  display: boolean = false;
  brodcasts: any
  currentPage: any;
  page: number = 1
  offers: any = {};
  regions: any = []
  all_regions: any = []
  offer_form!: FormGroup;
  edit: boolean = false;
  submit: boolean = false
  banner: any
  stores: any = []
  rewards: any = []
  rounds: any = []
  items: any = []
  region: any
  category: any
  expiration_status: any
  url: any
  urlType: any
  sections: any = []
  section: any
  urlSelector: any = [{ label: "None", value: null }, { label: "Lien externe", value: "externLink" }, { label: "Profil Etablissement", value: "store" }, { label: "Reward", value: "rewards" }, { label: "Rounds", value: "rounds" }, { label: "search", value: "search" }, { label: "catégories", value: "category" }, { label: "Créer une commande", value: "create_order" }]
  status: any = [{ label: 'Expiré', value: "expired" }, { label: 'Non expiré', value: "not_expired" }]
  store_id: any
  selected_store: any
  selected_items: any

  @ViewChild('deleteModal') deleteModal!: ConfirmationComponent;
  categories: any = [{ label: "food", value: "food" }, { label: "healthy", value: "healthy" }, { label: "fresh", value: "fresh" }, { label: "express", value: "express" }]
  search_categories: any = [{ label: "food", value: "food" }, { label: "fresh", value: "fresh" }, { label: "express", value: "express" }, { label: "errands", value: "errands" }, { label: "flights", value: "flights" }, { label: "topup", value: "topup" }]
  constructor(
    private httpService: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private fb: FormBuilder,
    public home: HomeComponent
  ) { }

  async ngOnInit() {
    await this.getRegions()
    this.initialise_form()
    this.offer_form.get('big_picture')?.valueChanges
      .subscribe(value => {
        this.offer_form.get('big_picture_path')?.updateValueAndValidity();
      });
    this.route.queryParamMap.subscribe(param => {
      Number(param.get('page')) ? this.page = Number(param.get('page')) : this.page = 1
      this.url = "offers?order_by=-created&page=" + this.page
      if (param.get('category') || param.get('region') || param.get('status')) {
        this.url += '&filters='
        this.category = param.get('category')
        this.region = param?.get('region') ? JSON.parse(param?.get('region') || '') : "",
          this.expiration_status = param.get('status')
        let today = new Date()
        param.get('status') === 'expired' ? this.url += 'expires_at:lse:' + today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() + ";" : ""
        param.get('status') === 'not_expired' ? this.url += 'expires_at:gte:' + today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() + ";" : ""
        param.get('category') ? this.url += 'category:' + param.get('category') + ";" : ""
        param.get('region') ? this.url += 'region:' + param.get('region') + ";" : ""
      }
      this.getOffers(this.url)
    })
    this.getStores("stores?order_by=name", 1)
    this.getRewards("points_rewards?order_by=-created", 1)
    this.getRounds("stories?order_by=-created", 1)
  }
  initialise_form() {
    this.offer_form = this.fb.group({
      name: new FormControl("", [Validators.maxLength(64), Validators.required]),
      category: new FormControl("", [Validators.maxLength(16), Validators.required]),
      url: new FormControl("", Validators.maxLength(128)),
      region_id: new FormControl(null,),
      starts_at: new FormControl("", Validators.required),
      expires_at: new FormControl("",),
      store: new FormControl("",),
      store_id: new FormControl("",),
      region: new FormControl("",),
      search: new FormControl("",),
      search_category: new FormControl("",),
      rewards: new FormControl("",),
      rounds: new FormControl("",),
      weight: new FormControl("",),
      big_picture_path: new FormControl("", Validators.maxLength(256)),
      big_picture: new FormControl("", this.ConditionallyRequiredValidator),
      all_regions: new FormControl(false)
    }, {
      validators: [this.ConditionallyRequiredValidator]
    });
  }
  get f() { return this.offer_form.controls }
  changeOrder() {
    let order: any = []
    let form: any
    this.regions.forEach((region: any) => {
      if (this.offers[region.label]) {
        this.offers[region.label].forEach((element: any) => {
          order.push(element.id)
        });
      }
    });
    form = { order: order, start: 1 }
    this.httpService.post("offers/update_order/", form).subscribe(data => {
      if (data?.status === 202) {
        this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Order a été modifié avec succès' });
      }
    })
  }
  getStores(url: any, page: any) {
    this.httpService.get(url + "&page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          this.stores.push(element)
        });
        if (data.body.meta.pagination.total_pages > page) {
          this.getStores(url, page + 1)
        }
      }
    })

  }
  getRewards(url: any, page: any) {
    this.httpService.get(url + "&page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          element.label = element.name + " - " + element.subtitle
          this.rewards.push(element)
        });
        if (data.body.meta.pagination.total_pages > page) {
          this.getRewards(url, page + 1)
        }
      }
    })
  }
  getRounds(url: any, page: any) {
    this.httpService.get(url + "&page=" + page).subscribe((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          element.label = element.id + "-" + element.name
          this.rounds.push(element)
        });
        if (data.body.meta.pagination.total_pages > page) {
          this.getRounds(url, page + 1)
        }
      }
    })
  }
  getOffers(url: any) {
    this.offers = {};
    this.httpService.get(url).subscribe((data: any) => {
      if (data?.status === 200) {
        this.brodcasts = data.body
        data.body.data.forEach((offer: any) => {
          if (offer.region === null) {
            if (!this.offers.hasOwnProperty('Tout')) {
              this.offers['Tout'] = [];
            }
            this.offers['Tout'].push(offer);
          } else {
            if (!this.offers.hasOwnProperty(offer.region.name)) {
              this.offers[offer.region.name] = [];
            }
            this.offers[offer.region.name].push(offer);
          }
        })
        this.isLoading = true
      }
    })
  }
  showPreviewImage(event: any,) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imgUrl = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
      this.selectedFile = <File>event.target.files[0];
      event.target.value = ""
      this.offer_form.patchValue({ big_picture: this.selectedFile })
    }
  }
  paginate(event: any) {
    this.page = event.page + 1;
    this.router.navigate(['/banner'], { queryParams: { page: this.page }, queryParamsHandling: "merge" });
  }
  async getRegions() {
    this.regions = new Array();
    await this.httpService.get('regions').toPromise().then((data: any) => {
      if (data?.status == 200) {
        data.body.data.forEach((element: any) => {
          this.all_regions.push({ label: element.name, value: element.id });
          this.regions.push({ label: element.name, value: element.id });
        });
        this.all_regions.unshift({ label: 'Tout', value: null });
      }
      // this.isLoading = true
    })
  }
  editBanner(banner: any) {
    this.selectedFile = null
    this.offer_form.patchValue({
      big_picture: null
    })
    this.banner = banner
    const tb = this.banner.url?.split('/');
    if (tb && Number(tb[2])) {
      switch (tb[1]) {
        case 'store':
          this.banner.store = this.stores.filter((store: any) => store.id === Number(tb[2]))[0]
          this.offer_form.patchValue({
            store: this.stores.filter((store: any) => store.id === Number(tb[2]))[0]?.id,
          })
          break;
        case 'create_order':
          console.log("create_order");

          // this.banner.store = this.stores.filter((store: any) => store.id === Number(tb[2]))[0]
          this.offer_form.patchValue({
            store_id: this.stores.filter((store: any) => store.id === Number(tb[2]))[0]?.id,
          })
          console.log(this.offer_form.value);

          break;
        case 'rewards':
          this.banner.rewards = this.rewards.filter((reward: any) => reward.id === Number(tb[2]))[0]
          this.offer_form.patchValue({
            rewards: this.rewards.filter((reward: any) => reward.id === Number(tb[2]))[0].id,
          })
          break;
        case 'rounds':
          this.banner.rounds = this.rounds.filter((round: any) => round.id === Number(tb[2]))[0]
          this.offer_form.patchValue({
            rounds: this.rounds.filter((round: any) => round.id === Number(tb[2]))[0].id,
          })
          break;
        default:
          break;
      }
    }
    if (tb && tb[2]) {
      switch (tb[1]) {
        case 'category':
          this.offer_form.patchValue({
            search_category: tb[2]
          })
          break;
        default:
          break;
      }
    }
    this.imgUrl = banner.big_picture_url
    this.edit = true;
    this.display = true;
    this.offer_form.patchValue({
      name: banner.name,
      category: banner.category,
      url: banner.url,
      region_id: banner.region?.id || null,
      starts_at: banner.starts_at.date ? new Date(banner.starts_at.date) : null,
      expires_at: banner.expires_at.date ? new Date(banner.expires_at.date) : null,
      weight: banner.weight,
      big_picture_path: banner.big_picture_url,
      store: banner.store?.id || null,
      rewards: banner.rewards?.id || null,
    });
    this.urlType = this.urlSelector?.filter((url: any) => url?.value === tb[1])[0]?.value
  }
  addBanner() {
    this.offer_form.patchValue({
      big_picture: null
    })
    this.display = true;
    this.edit = false;
    this.initialise_form()
    this.imgUrl = null;
  }
  changeUrl() {
    let url: any
    switch (this.urlType) {
      case "store":
        url = this.offer_form.get('store')?.value ? '/store/' + this.offer_form.get('store')?.value : ''
        break;
      case "rewards":
        url = this.offer_form.get('rewards')?.value ? '/rewards/' + this.offer_form.get('rewards')?.value : ''
        break;
      case "rounds":
        url = this.offer_form.get('rounds')?.value ? '/story/' + this.offer_form.get('rounds')?.value : ''
        break;
      case "externLink":
        url = 'https://'
        break;
      case "category":
        url = this.offer_form.get('search_category')?.value ? '/category/' + this.offer_form.get('search_category')?.value : ''
        break;
      case "search":
        url = this.offer_form.get('search')?.value ? '/search/' + this.offer_form.get('search')?.value : ''
        break;
      case "create_order":
        url = "/create_order/" + this.offer_form.get('store_id')?.value + '/' + this.selected_items.join(',')
        break;
      case "none":
        url = "-"
        break;
      default:
        url = '-'
        break;
    }
    this.offer_form.patchValue({
      url: url
    })
  }
  manageBanner() {
    this.submit = true
    this.offer_form.get('big_picture')?.updateValueAndValidity();
    let form = this.offer_form.value
    form.starts_at = this.offer_form.value.starts_at.getFullYear() + "-" + (this.offer_form.value.starts_at.getMonth() + 1) + "-" + this.offer_form.value.starts_at.getDate()
    form.expires_at = this.offer_form.value.expires_at.getFullYear() + "-" + (this.offer_form.value.expires_at.getMonth() + 1) + "-" + this.offer_form.value.expires_at.getDate()
    if (this.offer_form.invalid) {
      return
    }
    const fd = new FormData();
    fd.append("name", form.name);
    fd.append("category", form.category);
    fd.append("url", form.url);
    form.weight ? fd.append("weight", form.weight) : ""
    form.region_id && form.all_regions != true ? fd.append("region_id", form.region_id) : ""
    form.starts_at ? fd.append("starts_at", form.starts_at) : ""
    fd.append("expires_at", form.expires_at);
    fd.append("all_regions", form.all_regions === true ? "1" : "0");
    form.big_picture ? fd.append("big_picture", form.big_picture) : ""
    form.big_picture_path ? fd.append("big_picture_path", form.big_picture_path) : ""
    console.log(form);

    if (this.edit) {
      this.httpService.post("offer/" + this.banner.id, fd).subscribe((data: any) => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Offre a été modifié avec succès' });
          this.display = false;
          this.getOffers(this.url)
        }
      })
    } else {
      this.httpService.post("offer/", fd).subscribe((data: any) => {
        if (data?.status == 200) {
          this.messageService.add({ severity: 'success', summary: 'Opération Terminée', detail: 'Offre a été ajouté avec succès' });
          this.display = false;
          this.getOffers(this.url)
        }
      })
    }
  }

  filter() {
    this.isLoading = false
    let search: any = { category: this.category, region: this.region, status: this.expiration_status }
    search = { ...search, page: 1 }
    this.router.navigate(['/banner'], { queryParams: search, queryParamsHandling: 'merge' });
  }
  resetFilter() {
    this.category = null
    this.expiration_status = null
    this.region = null
    this.router.navigate(['/banner'], { queryParams: { category: null, region: null, status: null, } });
  }
  ConditionallyRequiredValidator(formControl: any) {
    if (!formControl.parent) {
      return null;
    }
    if (formControl.parent.get('big_picture_path').value === null) {
      console.log(formControl);
      return Validators.required(formControl);
    }
    return null;
  }
  delete_notification(banner: any) {
    this.deleteModal.displayModal = true;
    this.banner = banner
  }
  create_order() {
    this.httpService.get('menu/' + this.offer_form.get('store_id')?.value + '/' + this.section + '/items').subscribe(res => {
      if (res?.status == 200) {
        this.items = res.body.data
      }
    })
  }
  selectStore(event: any) {
    console.log(event);
    this.selected_store = event.value
    this.httpService.get('menu/' + event.value + '/sections').subscribe(res => {
      if (res?.status == 200) {
        this.sections = res.body.data
      }
    })
  }
  onConfirm(event: any) {
    this.httpService.delete("offer/" + this.banner.id).subscribe(res => {
      if (res?.status === 204) {
        this.deleteModal.displayModal = false;
        this.display = false
        this.getOffers(this.url)

      }
    })
  }
  change_Region(event: any) {
    this.stores.length = 0
    this.getStores("stores?filters=region:" + event.value, 1)
  }
}

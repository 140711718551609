<div class="white-back" *ngIf="isLoading;else notYetLoaded ">
  <div class="pt-6 pb-4 d-flex align-items-center px-3">
    <button class="btn p-0" (click)="back()">
      <img src="../../../../../../assets/images/svg/Icon-arrow.svg" class="mr-2 backArrow" alt="">
    </button>
    <span class="avenirArabic f30 fw85 black">{{section?.name}}</span>
  </div>
  <div #content id="content">
    <p-table *ngIf="items!.length>0;else noItems" [value]="items" [reorderableColumns]="true" responsiveLayout="scroll"
      [resizableColumns]="true">
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width:3rem"></th>
          <th style="width:2%"></th>
          <th>Nom</th>
          <th class="descriptionHeader">Description</th>
          <th>Prix</th>
          <th>Prix d'achat</th>
          <th>Disponibilité</th>
          <th>Opérations</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex">
        <tr [pReorderableRow]="index">
          <td>
            <img src="../../../../../../assets/images/svg/Group380.svg" (dragend)="changeOrder()"
              [pReorderableRowHandle]="index" alt="">
          </td>
          <td><img *ngIf="rowData.image.url;else noImg" src="{{rowData.image.url}}" class="img" alt="">
          </td>
          <td class="f16 avenirArabic black">
            {{rowData.name}}
            <div class="d-flex">
              <div class="bar_stats red" [ngStyle]="{width: rowData.availability_stats.stats.not_available*2+'%'}"
                pTooltip='{{rowData.availability_stats.stats.not_available}}' tooltipPosition="top"></div>
              <div class="bar_stats green" [ngStyle]="{width: rowData.availability_stats.stats.available*2+'%'}"
                pTooltip='{{rowData.availability_stats.stats.available}}' tooltipPosition="top">
              </div>
            </div>
          </td>
          <td class="f16 avenirArabic greyColor normalWhiteSpace">{{rowData.description}}</td>
          <td class="f18 avenirArabic"><span class="main-color">{{rowData.price}}</span><span
              class="greyColor">{{appconfig.config.langs[0].currency_symbol}}</span></td>
          <td class="f18 avenirArabic"><span class="main-color">{{rowData.buy_price}}</span><span
              class="greyColor"> {{rowData.buy_price?appconfig.config.langs[0].currency_symbol:''}}</span></td>
          <td>
            <p-inputSwitch [(ngModel)]="rowData.available" (click)="changeAvailability(rowData)">
            </p-inputSwitch>
          </td>
          <td>
            <button class="btn p-0 mr-2" (click)="editItem(rowData)">
              <img src="../../../../../../assets/images/svg/Icon - pen.svg" alt="">
            </button>
            <button class="btn p-0" (click)="deleteItem(rowData)">
              <img src="../../../../../../assets/images/svg/delete..svg" alt="">
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <app-manage-items id="manage_items" #manage_items [item]="item" [storeId]="storeId" [section_id]="sectionId"
    [items]="items"></app-manage-items>
</div>
<ng-template #noItems>
  <div class="text-center black  avenirBlack f18 p-3">Cet section n'a pas encore des produits appuyez sur "Ajouter"
    pour en ajouter un</div>
</ng-template>
<ng-template #noImg>
  <img src="../../../../../../assets/images/png/Frame 1322.png" alt="">
</ng-template>
<ng-template #notYetLoaded>
  <div class="d-flex align-items-center justify-content-center h-100">
    <app-loading></app-loading>
  </div>
</ng-template>
<p-toast position="bottom-right"></p-toast>
<app-confirmation #deleteModal (onConfirm)="onConfirm($event)" [name]="'item '+this.item?.name"></app-confirmation>
